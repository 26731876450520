'use client';

import { useState, useEffect } from 'react';
import { Dialog } from './ui/dialog';
import { Button } from './ui/button';

interface AgeVerificationProps {
  onlyFirstVisit?: boolean;
  minimumAge?: number;
  logo?: string;
  backgroundImage?: string;
}

export function AgeVerification({ 
  onlyFirstVisit = true,
  minimumAge = 18,
  logo = 'https://yhbrewery.com/age-gate/yh-logo.png',
  backgroundImage = '/background.jpg'
}: AgeVerificationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const hasVerified = localStorage.getItem('age-verified');
    if (onlyFirstVisit && hasVerified) {
      setIsVerified(true);
      return;
    }
    setIsOpen(true);
  }, [onlyFirstVisit]);

  const handleVerify = () => {
    setIsVerified(true);
    setIsOpen(false);
    localStorage.setItem('age-verified', 'true');
  };

  const handleDeny = () => {
    window.location.href = 'https://www.google.com';
  };

  if (isVerified) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <div 
        className="fixed inset-0 bg-black/40 backdrop-blur-[8px] z-50 flex items-center justify-center p-4"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5))`,
        }}
      >
        <div className="relative bg-black/30 rounded-xl max-w-lg w-full mx-auto overflow-hidden border border-white/10 shadow-2xl backdrop-blur-2xl backdrop-saturate-150">
          <div className="absolute inset-0 bg-gradient-to-br from-white/[0.075] to-white/[0.025] pointer-events-none" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/5 to-black/20 pointer-events-none" />
          
          <div className="relative z-10 p-8 sm:p-10 text-center">
            <img
              alt="YH Brewery"
              className="mx-auto mb-8 h-28 w-auto"
              src={logo}
              style={{ filter: 'brightness(1)' }}
            />
            
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4 tracking-tight">
              Age Verification Required
            </h2>
            
            <p className="text-white mb-8 text-sm sm:text-base max-w-md mx-auto leading-relaxed">
              You must be {minimumAge} years or older to enter YH Brewery's website.
              Please verify your age to continue.
            </p>
            
            <div className="space-y-4 max-w-sm mx-auto">
              <Button
                onClick={handleVerify}
                className="w-full bg-white/10 hover:bg-white/20 text-white font-semibold py-3 transition-all duration-200 backdrop-blur-sm border border-white/10 shadow-lg shadow-black/20 hover:shadow-xl hover:shadow-black/30"
              >
                I am {minimumAge} years or older
              </Button>
              
              <Button
                onClick={handleDeny}
                variant="outline"
                className="w-full bg-black/20 hover:bg-black/30 border-white/10 text-white hover:text-white transition-all duration-200 backdrop-blur-sm"
              >
                I am under {minimumAge}
              </Button>
            </div>
            
            <p className="mt-8 text-xs text-white">
              By entering this site you are agreeing to our Terms of Service and Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
